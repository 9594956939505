<template>
  <div>
    <!-- input search -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group>
        <div class="d-flex align-items-center mt-3">
          <label class="mr-1 h3">Order status</label>
          <b-form-radio-group
            v-model="status"
            @input="getOrders"
          >
            <b-form-radio
              :value="0"
              class="h3"
            >
              In way to Tunisia
            </b-form-radio>
            <b-form-radio
              value="1"
              class="h3"
            >
              Received in Tunisia
            </b-form-radio>

          </b-form-radio-group>
        </div>
      </b-form-group>
    </div>
    <div class="d-flex justify-content-end">

      <div class="d-flex align-items-center mb-1 mr-1">
        <span class="title mr-1">
          Date:
        </span>
        <flat-pickr
          v-model="date"
          class="form-control invoice-edit-input"
          @input="getOrders"
        />
      </div>
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
      <b-form-group>
        <b-button
          :to="{ name: 'create_order' }"
          variant="gradient-primary"
          class="ml-1 d-inline-block"
        >
          <feather-icon icon="PlusIcon" />
          <span class="ml-1">Create order</span>
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="orders"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'etat'">

          <div v-if="props.row.status === 0">
            <b-badge
              class="badge-glow"
              pill
              variant="warning"
            >
              <feather-icon
                icon="SendIcon"
                class="mr-25"
              />
              <span>In way to Tunisia </span>
            </b-badge>
          </div>
          <div v-if="props.row.status === 1">
            <b-badge
              class="badge-glow"
              pill
              variant="success"
            >
              <feather-icon
                icon="CheckIcon"
                class="mr-25"
              />
              <span>Received in tunisia</span>
            </b-badge>
          </div>

        </span>
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <span>
            <feather-icon
              :id="`invoice-row-${props.row.id}-show-icon`"
              icon="EyeIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="
                $router.push({
                  name: 'show-order',
                  params: { id: props.row.id} ,
                })
              "
            />
            <b-tooltip
              title="Detail"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-show-icon`"
            />
            <feather-icon
              v-if="props.row.status === 0"
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="
                $router.push({
                  name: 'edit-order',
                  params: { id: props.row.id} ,
                })
              "
            />
            <b-tooltip
              v-if="props.row.status === 0"
              title="Edit"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />
          </span>
        </span>

        <!-- Column: Common -->

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BPagination, BFormGroup, BFormInput, BFormSelect, BButton, BTooltip, BBadge, BFormRadio, BFormRadioGroup,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BTooltip,
    BButton,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    flatPickr,
  },
  props: {
    supplier: {
      type: String,
      default: () => null,
    },
  },
  data() {
    return {
      date: null,
      status: '',
      pageLength: 10,
      rangeDate: null,
      dir: false,
      columns: [
        {
          label: 'Number',
          field: 'number',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher bl',
          },
        },
        {
          label: 'status',
          field: 'etat',
          filterOptions: {
            enabled: true,
            placeholder: 'Chercher client',
          },
        },

        {
          label: 'Date de création',
          field: 'created_at',
          formatFn: this.formatFn,
          filterOptions: {
            enabled: true,
            placeholder: 'Recherche date',
          },
        },

        {
          label: 'Action',
          field: 'action',
        },
      ],
      role: '',
      orders: [],
      user: {},
      searchTerm: '',
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current      : 'light-primary',
        Professional : 'light-success',
        Rejected     : 'light-danger',
        Resigned     : 'light-warning',
        Applied      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getOrders()
  },
  methods: {
    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(2, '0')} | ${h}`
    },

    async getOrders() {
      this.load = 'true'
      try {
        const { data } = await axiosIns.get('/orders/', {
          params: {
            status: this.status,
            date: this.date,
          },
        })
        this.orders = data
        this.load = 'false'
      } catch (error) {
        this.showToast('danger', 'top-center', error.toString())
      }
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },

}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
